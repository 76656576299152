// $themeColor: #ab1e05;
$themeColor: #b0b0b0;
$themetxt: #2c4fa2;

html,
body,
#root {
	position: relative;
	min-height: 100vh;
	// font-family: 'Roboto', sans-serif;
	font-family: 'Lato', sans-serif;
}
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $themeColor;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #921702;
}
body {
	overflow-x: hidden;
}
html {
	scroll-behavior: smooth !important;
}

html p {
	margin: 0;
}
.warning-span {
	margin: 5px 10px;
	padding: 5px;
	font-weight: 600;
		color: rgb(182, 112, 21);
}

.done-span {
	margin: 5px 10px;
	padding: 5px;
	font-weight: 600;
		color: rgb(33, 161, 76);
}
.bg-theme {
	background-color: $themeColor !important;
}
.txt-theme {
	color: $themeColor !important;
}
.w-100 {
	width: 100%;
}
.home-swiper {
	width: 100%;
	height: 500px;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

//Custom-Classes
.size-full {
	height: 100%;
	width: 100%;
}

.size-full-width {
	width: 100%;
}

.size-full-height {
	height: 100%;
}

.lr-0 {
	left: 0;
	right: 0;
}

.z-index-0 {
	z-index: 0;
}

.z-index-1 {
	z-index: 100;
}

.text-justify {
	text-align: justify;
}

.service-card-width {
	width: 340px;
}

.flex-align-center {
	display: flex;
	align-items: center;
}
.page-header {
	width: 100%;
	height: 200px;
	display: flex;
	justify-content: center;
	padding: 20px;
	background-repeat: no-repeat;
	background-size: cover;
	h1 {
		// color: white;
			color: $themetxt;
		font-weight: 800;
		font-size: 50px;
	}
}
.about-page {
	.about-header-inner {
		width: 1000px;
		max-width: 1000px;
		display: flex;
		align-items: center;
	}

	.our-story {
		padding: 100px 0;
		display: flex;
		flex-flow: row wrap;
		img {
			width: 50%;
			height: auto;
		}
		.story {
			width: 50%;
			padding: 10px 30px;
			h2 {
				margin-bottom: 20px;
			}
			p {
				margin-bottom: 20px;
			}
		}
	}
	.our-focus {
		padding: 100px 0;
		display: flex;
		flex-flow: row wrap;
		.left,
		.right {
			width: 50%;
			height: auto;
			padding: 20px 10px;
			h3 {
				margin-bottom: 30px;
			}
			.focus-line {
				display: flex;
				margin-top: 20px;
				.number {
					width: 50px;
					height: 50px;
					color: white;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					background-color: $themeColor;
					font-size: 25px;
				}
				.focus-data {
					width: calc(100% - 50px);
					padding: 0 20px;
					h4 {
						font-size: 20px;
					}
				}
			}
			.icons-line {
				width: 100%;
				display: flex;
				.icon-block {
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					h4 {
						font-size: 20px;
						font-weight: 800;
						text-align: center;
					}
					i {
						color: $themeColor;
						font-size: 60px;
						padding: 30px;
					}
				}
			}
		}
	}
}

.footer-logo-moto {
	.logo-moto {
		display: flex;
	}
	img {
		height: 50px;
		width: auto;
		margin: 10px;
	}
	h6 {
		max-width: 300px;
		width: auto;
		text-align: left !important;
		padding: 0 10px;
	}
}
.footer-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-flow: row wrap;
}
.footer-details {
	width: 50%;
	display: flex;
	flex-direction: column;
	.footer-link {
		color: #959595;
		text-decoration: none;
		padding: 10px;
	}
}
.bottom-line {
	width: 100%;
	min-height: 40px;
	background-color: $themeColor;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row wrap;
	padding: 10px;
	a {
		color: white;
		font-weight: 1000;
		text-decoration: none;
	}
	a:hover {
		color: rgb(201, 201, 201);
	}
	div {
		padding: 0 5px;
	}
}

.new-nav-bar {
	background-color: $themeColor;
	width: 100%;
	height: 80px;
	z-index: 1000000 !important;
	.container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		.nav-item-container {
			display: flex;
			height: 80px;
			align-items: center;

			.burger {
				display: none;
				padding: 10px;
				cursor: pointer;
				margin-right: 20px;
				.bar {
					width: 30px;
					height: 3px;
					background-color: $themetxt;
					margin: 5px 0;
				}
			}
			.logo {
				height: 80px;
				display: flex;
				align-items: center;
				img {
					height: 60px;
					width: auto;
				}
			}
			.all-nav-items {
				display: flex;
				align-items: center;
				height: 80px;
				list-style: none;
				padding: 0 20px !important;
				margin: 0 !important;
				z-index: 10000 !important;
				.nav-item {
					color: $themetxt;
					text-decoration: none;
					padding: 15px 5px;
				}
			}
		}
		.nav-profile-container {
			.profile-icon {
				cursor: pointer;
				width: 45px;
				height: 45px;
				border-radius: 50%;
				background-color: $themetxt;
				color: white;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 2vh;
				font-weight: 600;
			}
			.notification-container {
				cursor: pointer;
				position: relative;
				span {
					position: absolute;
					min-width: 20px;
					height: 20px;
					background-color: rgb(118, 118, 118);
					color: white;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 10px;
					top: 0;
					right: 10px;
					font-size: 12px;
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.service-card-width {
		width: 80vw;
	}
	.our-story {
		img {
			width: 100% !important;
			height: auto;
		}
		.story {
			width: 100% !important;
			padding: 10px 30px;
		}
	}

	.our-focus {
		.left,
		.right {
			width: 100% !important;
			height: auto;
		}
	}
	.footer-details {
		width: 100% !important;
		.footer-link {
			padding: 5px;
		}
	}
}

.profile-popup {
	z-index: 10000 !important;
	position: absolute;
	right: 0;
	top: 80px;
	.profile-popup-container {
		width: 200px;
		display: flex;
		flex-direction: column;
		list-style: none;
		position: relative;
		.profile-popup-link {
			width: 100%;
			height: 35px;
			background-color: $themeColor;
			color: white;
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid rgba(0, 0, 0, 0.05);
		}
		.profile-popup-link:hover {
			background-color: $themetxt;
		}
	}
}

@media only screen and (max-width: 800px) {
	.nav-item-container {
		.burger {
			display: block !important;
			position: relative;
			width: 50px;
			height: 50px;
			.bar {
				width: 30px;
				height: 3px;
				background-color: white;
				transform-origin: center center;
			}
		}
		.bugger-clicked {
			.bar {
				margin: 0 !important;
				position: absolute;
				left: 20%;
				top: 50%;
				transform: translateY(-50%);
			}
			.b1 {
				transform: rotate(45deg);
			}
			.b2 {
				transform: scale(0);
			}
			.b3 {
				transform: rotate(-45deg);
			}
		}
		.all-nav-items {
			position: absolute;
			left: 0;
			right: 0;
			top: 80px;
			width: 100vw;
			background-color: $themeColor;
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 80px;
			list-style: none;
			padding: 0 20px !important;
			margin: 0 !important;
			.nav-item {
				width: 100vw;
				color: white;
				text-decoration: none;
				padding: 15px 5px;
				background-color: $themeColor;
				display: flex;
				justify-content: center;
				border: 1px solid rgba(0, 0, 0, 0.2);
			}
			.nav-item:hover {
				// background-color: #841d0b;
					background-color: #cccccc;
			}
		}
	}
}
.about-line {
	position: absolute;
	left: 0;
	right: 0;
	height: 20px;
	width: 100%;
}

.two-side-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 100px 0;
	.half {
		width: 49%;
		img {
			width: 100%;
			height: auto;
			object-fit: cover;
		}
		h3 {
			padding: 20px 0 !important;
		}
	}
}

@media only screen and (max-width: 800px) {
	.two-side-container {
		flex-direction: column;
		.half {
			width: 100%;
			padding: 20px 0;
		}
	}
}

.products-and-equipments {
	.products-container {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-flow: row wrap;
		.product-box {
			width: 32%;
			display: flex;
			align-items: center;
			flex-direction: column;
			margin: 20px calc(4% / 6);
			padding: 10px;
			.img {
				width: 100%;
				height: 300px;
				display: flex;
				justify-content: center;
				img {
					height: 300px;
					width: auto;
				}
			}
			p {
				text-align: center;
			}
			a {
				color: $themeColor;
				text-decoration: none;
			}
			a:hover {
				color: #ff2802;
			}
		}
	}
}

@media only screen and (max-width: 800px) {
	.products-and-equipments {
		.products-container {
			width: 100%;
			display: flex;
			justify-content: center;
			flex-flow: row wrap;
			.product-box {
				width: 100%;
				margin: 20px calc(4% / 6);
				padding: 10px;
				.img {
					width: 100%;
					height: 300px;
					display: flex;
					justify-content: center;
					img {
						height: 300px;
						width: auto;
					}
				}
			}
		}
	}
}

.equipments-container {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
	.eq-img {
		width: 15vw;
		height: 15vw;
		margin: 10px;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		cursor: pointer;
	}
	.eq-img:hover {
		transform: scale(1.01);
		box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
	}
}

@media only screen and (max-width: 800px) {
	.equipments-container {
		width: 100%;
		display: flex;
		justify-content: center;
		.eq-img {
			width: 40vw;
			height: 40vw;
		}
	}
}

.preview-container {
	position: fixed;
	z-index: 100000 !important;
	.preview-bg {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
	}
	.preview-img {
		z-index: 1000001 !important;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.img {
			z-index: 2000000 !important;
			max-width: 90vw;
			max-height: 90vh;
			width: 90vw;
			height: 90vh;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
		}
	}
	.preview-close {
		z-index: 1000001 !important;
		position: fixed;
		right: 20px;
		top: 10px;
		cursor: pointer;
		i {
			color: white;
			font-size: 50px;
		}
	}
}

.contact-us {
	.contacts-card-container {
		display: flex;
		flex-flow: row wrap;
		width: 100%;
		justify-content: space-between;
		padding: 0 10%;
		.contact-card {
			width: 30%;
			height: auto;
			box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 50px 20px;
			border-radius: 4px;
			i {
				font-size: 80px;
				color: $themeColor;
				margin-bottom: 40px;
			}
		}
	}
	.map-container {
		width: 100%;
		.location-in-map {
			width: 100% !important;
			height: 50vh !important;
		}
	}
	.contact-form {
		input {
			width: 100%;
			height: 50px;
			background-color: rgba(0, 0, 0, 0.1);
			border: none;
			outline: none;
			padding: 0 20px;
			margin: 10px 0;
		}
		.name-email {
			width: 100%;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			input {
				width: 48%;
			}
		}
		textarea {
			width: 100%;
			height: 200px;
			background-color: rgba(0, 0, 0, 0.1);
			border: none;
			outline: none;
			padding: 20px;
			margin: 10px 0;
			resize: horizontal;
		}
		.send-btn {
			width: 150px;
			background-color: $themeColor;
			color: white;
			border-radius: 0;
			font-weight: 300;
			height: 40px;
			font-size: 16px;
		}
	}
}

@media only screen and (max-width: 800px) {
	.contact-us {
		.contacts-card-container {
			.contact-card {
				width: 100%;
				margin: 20px 0;
			}
		}
		.contact-form {
			.name-email {
				input {
					width: 100%;
				}
			}
			.send-btn {
				width: 100% !important;
				height: 50px !important;
			}
		}
	}
}

.login-page {
	.login-card-container {
		width: 100%;
		height: 600px;
		display: flex;
		justify-content: center;
		align-items: center;
		.login-card {
			display: flex;
			flex-direction: column;
			max-width: 400px;
			width: 400px;
			height: 500px;
			border-radius: 4px;
			box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
			justify-content: space-around;
		}
	}
}

.register-page {
	.register-container {
		width: 100%;
		min-height: 100vh;
		padding: 10vh 0;
		display: flex;
		justify-content: center;
		.register-card {
			width: 800px;
			min-height: 80vh;
			border-radius: 4px;
			box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
			.register-form {
				width: 100%;
				padding: 20px;
				.upload-label {
					padding: 10px 15px;
					border: 2px solid $themeColor;
					color: $themeColor;
					margin: 10px 0;
					cursor: pointer;
				}
				.upload-label:hover {
					border: 2px solid white;
					background-color: $themeColor;
					color: white;
				}
				.input-file {
					display: none;
				}
				.upload-document-container {
					width: 100%;
					display: flex;
					justify-content: space-between;
					flex-flow: row wrap;
					.upload-document-box {
						width: 48%;
						display: flex;
						justify-content: center;
						flex-direction: column;
						align-items: center;
						label {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 800px) {
	.upload-document-container {
		flex-direction: column;
		.upload-document-box {
			width: 100% !important;
		}
	}
}

.txt-green{
	color: rgb(18, 181, 18) !important;
}

.txt-red{
	color: red !important;
}

.txt-yellow{
	color: rgb(232, 192, 30) !important;
}
.galary-container {
	width: 100%;
	display: flex;
	flex-flow: row wrap;

	.clicked {
		.inner {
			border: 5px solid $themeColor !important;
		}
	}

	.img-box {
		width: 25%;
		height: 300px;
		background-color: white;
		padding: 5px;
		border: 2px solid white;

		.inner {
			width: 100%;
			height: 290px;
			background-color: #959595;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
				cursor: pointer;
			}

			.close-btn {
				position: absolute;
				border-radius: 50%;
				background-color: red;
				color: white;
				padding: 2px;
				right: -7px;
				top: -7px;
				cursor: pointer;
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	.galary-container {
		.img-box {
			width: 33.33%;
		}
	}
}

@media only screen and (max-width: 767px) {
	.galary-container {
		.img-box {
			width: 50%;
		}
	}
}

@media only screen and (max-width: 480px) {
	.galary-container {
		.img-box {
			width: 100%;
			height: 400px;

			.inner {
				height: 390px;
			}
		}
	}
}

.show-img-container {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999 !important;

	.img-window {
		position: relative;
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			max-width: 90%;
			max-height: 90vh;
			z-index: 99999 !important;
		}
	}

	.img-bg {
		position: absolute;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
	}
}
.new-invoice-container {
	position: relative;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 99999 !important;

	.inner-page {
		width: 100%;
		background-color: white;
		padding: 50px 0;

		.header {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

.txt-right {
	text-align: right;
}
.collection-form {
	width: 100%;
	border-radius: 10px;
	box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.145);

	hr {
		margin: 40px 0;
	}
}