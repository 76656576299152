.admin-nav-bar {
  width: 100%;
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: auto;
}

.admin-nav-bar .nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.admin-nav-bar .nav-list .nav-item {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 5px 10px;
  color: #ab1e05;
  text-decoration: none;
  font-weight: bold;
  font-size: 110%;
  position: relative;
}

.admin-nav-bar .nav-list .nav-item::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 3px;
  background-color: #ab1e05;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.admin-nav-bar .nav-list .nav-item:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.admin-nav-bar .nav-list .active {
  position: relative;
}

.admin-nav-bar .nav-list .active::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 3px;
  background-color: #ab1e05;
}

.time-date-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.fw-33 {
  width: 33%;
}

.select-days-to-work td {
  border: 1px solid #8e8e8e;
}

.select-days-to-work tbody td {
  width: 50px !important;
  height: 50px !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.select-days-to-work tbody td label {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.select-days-to-work tbody td input {
  margin: 0 20px;
}

@media only screen and (max-width: 800px) {
  .fw-33 {
    width: 100%;
  }
}

.all-jobs-cards-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.all-jobs-cards-container .job-card {
  width: 25%;
  height: 150px;
  padding: 10px;
}

.all-jobs-cards-container .job-card .inner-card {
  cursor: pointer;
  width: 100%;
  height: 130px;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
}

.all-jobs-cards-container .job-card .inner-card h3 {
  width: 100%;
  word-wrap: break-word;
}

@media only screen and (max-width: 990px) {
  .all-jobs-cards-container .job-card {
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .all-jobs-cards-container .job-card {
    width: 100%;
  }
}

.quick-job-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.quick-job-container .quick-job-card {
  width: 33.33%;
  padding: 8px;
}

.quick-job-container .quick-job-card .inner {
  padding: 15px;
  width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
}
