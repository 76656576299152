.text-secondary-d1 {
	color: #728299 !important;
}

.invoice-header {
	margin: 0 0 1rem;
	padding-bottom: 1rem;
	padding-top: 0.5rem;
	border-bottom: 1px dotted #e2e2e2;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: center;
	align-items: center;
}
.page-title {
	padding: 0;
	margin: 0;
	font-size: 1.75rem;
	font-weight: 300;
}
.brc-default-l1 {
	border-color: #dce9f0 !important;
}

.ml-n1,
.mx-n1 {
	margin-left: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
	margin-right: -0.25rem !important;
}
.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text-grey-m2 {
	color: #888a8d !important;
}

.text-success-m2 {
	color: #86bd68 !important;
}

.font-bolder,
.text-600 {
	font-weight: 600 !important;
}

.text-110 {
	font-size: 110% !important;
}
.text-blue {
	color: #478fcc !important;
}
.pb-25,
.py-25 {
	padding-bottom: 0.75rem !important;
}

.pt-25,
.py-25 {
	padding-top: 0.75rem !important;
}
.bgc-default-tp1 {
	background-color: rgba(121, 169, 197, 0.92) !important;
}
.bgc-default-l4,
.bgc-h-default-l4:hover {
	background-color: #f3f8fa !important;
}
.page-header .page-tools {
	-ms-flex-item-align: end;
	align-self: flex-end;
}

.btn-light {
	color: #757984;
	background-color: #f5f6f9;
	border-color: #dddfe4;
}
.w-2 {
	width: 1rem;
}

.text-120 {
	font-size: 120% !important;
}
.text-primary-m1 {
	color: #4087d4 !important;
}

.text-danger-m1 {
	color: #dd4949 !important;
}
.text-blue-m2 {
	color: #68a3d5 !important;
}
.text-150 {
	font-size: 150% !important;
}
.text-60 {
	font-size: 60% !important;
}
.text-grey-m1 {
	color: #7b7d81 !important;
}
.align-bottom {
	vertical-align: bottom !important;
}
