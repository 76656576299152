.hm-200{
    min-height: 200px;
    height: auto;
}
.hm-400{
    min-height: 400px;
    height: auto;
}
.card-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.my-card{
    width: 49%;
    border-radius: 15px;
    box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.1);
    background-color: white;
}

.txt-green{
    color: green;
}

.go-back{
    color: rgb(0, 136, 255);
    cursor: pointer;
    font-size: 16px;
}

.jobs-card-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    .job-card{
        width: 33.33%;
        height: 310px;
        padding: 5px;
        .inner{
            width: 100%;
            height: 300px;
            background-color: white;
            border-radius: 10px;
            box-shadow: 2px 2px 7px 2px rgba(0,0,0,0.1);
            a{
                text-decoration: none;
            }
        }
    }
}

.table-container{
    max-width: 450px;
    color: black;
    .header{
        width: 100%;
        height: 50px;
        display: flex;
        .cell{
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid black;
            border-left: none;
        }
        .cell:nth-child(1){
            width: 100px;
            border: 1px solid black;
        }
    }
    .table-body{
        width: 100%;
        .table-row{
            width: 100%;
            height: 50px;
            display: flex;
            .cell{
                width: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid black;
                border-left: none;
            }
            .cell:nth-child(1){
                width: 100px;
                border: 1px solid black;
            }
        }
    }
}

.jobs-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    .job-card-container{
        width: 33.33%;
        padding: 10px;
        .job-card{
            width: 100%;
            background-color: white;
            border-radius: 10px;
            box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.1);
            div{
                height: 35px;
                display: flex;
                align-items: center;
                font-size: 18px;
                p{
                    padding-left: 5px;
                }
            }
        }
    }
}


.quick-jobs-cards-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    .job-card-container{
        width: 33.33%;
        padding: 10px;
        .job-card{
            width: 100%;
            background-color: white;
            border-radius: 10px;
            box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.1);
            div{
                height: 35px;
                display: flex;
                align-items: center;
                font-size: 18px;
                p{
                    padding-left: 5px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px){
    .jobs-container{
        .job-card-container{
            width: 50%;
        }
    }
}
@media only screen and (max-width: 800px){
    .my-card{
        width: 100%;
    }
    .card-container{
        flex-direction: column;
    }
    .jobs-container{
        .job-card-container{
            width: 100%;
        }
    }
}
@media only screen and (max-width: 1000px){
    .jobs-card-container{
        .job-card{
            width: 50%;
        }
    }
}
@media only screen and (max-width: 700px){
    .jobs-card-container{
        .job-card{
            width: 100%;
        }
    }
}

.sub-contractors{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    .sub-contractor-card{
        width: 50%;
        padding: 10px;
        .inner{
            cursor: pointer;
            background-color: white;
            width: 100%;
            min-height: 50px;
            border-radius: 10px;
            box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
            padding: 15px 20px;
        }

    }
}
@media only screen and (max-width: 600px) {
    .sub-contractors {
        .sub-contractor-card {
            width: 100%;

        }
    }
}