$themeColor:#ab1e05;
.admin-nav-bar{
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    height: auto;
    .nav-list{
        display: flex;
        flex-flow: row wrap;
        .nav-item{
            width: max-content;
            margin: 5px 10px;
            color: $themeColor;
            text-decoration: none;
            font-weight: bold;
            font-size: 110%;
            position: relative;
        }
        .nav-item::before{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 3px;
            background-color: $themeColor;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.2s ease-in-out;
        }
        .nav-item:hover::before{
            transform: scaleX(1);
        }
        .active{
            position: relative;
        }
        .active::before{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 3px;
            background-color: $themeColor;
        }
    }
}

.time-date-line{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.fw-33{
    width: 33%;
}

.select-days-to-work{
    td{
        border: 1px solid rgb(142, 142, 142);
    }
    tbody{
        td{
            width: 50px !important;
            height: 50px !important;
            align-items: center;
            label{
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
            }
            input{
                margin: 0 20px;
                
            }
        }
    }
}

@media only screen and (max-width: 800px){
    .fw-33{
        width: 100%;
    }
}

.all-jobs-cards-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    .job-card{
        width: 25%;
        height: 150px;
        padding: 10px;
        .inner-card{
            cursor: pointer;
            width: 100%;
            height: 130px;
            border-radius: 10px;
            background-color: white;
            box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
            h3{
                width: 100%;
                word-wrap: break-word;
            }
        }
    }
}

@media only screen and (max-width: 990px){
    .all-jobs-cards-container{
        .job-card{
            width: 50%;
        }
    }
}

@media only screen and (max-width: 500px){
    .all-jobs-cards-container{
        .job-card{
            width: 100%;
        }
    }
}
.quick-job-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    .quick-job-card {
        width: 33.33%;
        padding: 8px;

        .inner {
            padding: 15px;
            width: 100%;
            border-radius: 5px;
            box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
        }
    }
}